import React, { useState, useEffect, Fragment } from 'react';
import {
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiSpacer,
    EuiTextColor,
    EuiText,
    EuiLink,
} from '@elastic/eui';
import { useIntl, FormattedMessage } from 'react-intl';
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'

import { setAccountDetails } from '../../api/services/user';
import useUserAll from '../../api/useUserAll'
import SaveButton from '../../components/SaveButton';

import { hasRight } from '../../helpers/ACL'

const CustomPhoneInput = React.forwardRef((props, ref) => <EuiFieldText inputRef={ref} {...props} />)

const UserProfile = ({ user }) => {
    const intl = useIntl()

    const [loadingUser, errorUser, userData, isDiff, setUserData] = useUserAll()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [errorManufacturers, setErrorManufacturers] = useState(null)

    const [name, setName] = useState(user?.name ? user.name : "");
    const [mobile, setMobile] = useState(user?.address?.mobile ? user.address.mobile : "");

    const [canEditUser, setCanEditUser] = useState(false)

    useEffect(() => {
        setCanEditUser(hasRight('account.profiledetails.canEdit'))
    }, [])

    useEffect(() => {
        setName(userData?.name ? userData?.name : "")
        setMobile(userData?.address?.mobile ? userData?.address.mobile : "")
    }, [userData])

    const _handleSave = async () => {
        setLoading(true)
        setError(null)
        setErrorManufacturers(null)

        const apiData = {
            name,
            address: {
                mobile,
            }
        }

        try {
            const res = await setAccountDetails(user.id, apiData)

            setUserData(res.data)

            const isError = res.data.some(({ error }) => error)

            if (isError) {
                const _errorManufacturers = res.data.map(({ manufacturer, error }) => ({ manufacturer, error }))

                setErrorManufacturers(_errorManufacturers)
            }

        } catch (error) {
            const errors = [];

            if (error?.data?.msg) {
                errors.push(error.data.msg)
            } else {
                errors.push(intl.formatMessage({ id: "Dashboard.UserProfile.generalError" }))
            }

            if (typeof error === "string") {
                errors.push(error)
            }

            if (error?.status) {
                errors.push(`${intl.formatMessage({ id: "Dashboard.UserProfile.status" })} ${error.status}`)
            }

            setError(errors)
        }


        setLoading(false)
    }

    const getOldUserData = () => {
        const name = userData?.name
        const mobile = userData?.address?.mobile

        const _oldUser = {
            name: name ? name : "",
            mobile: mobile ? mobile : "",
        }

        return _oldUser;
    }

    const getNewUserData = () => {
        return {
            name,
            mobile,
        }
    }

    const getIsChanges = () => {
        const oldUserData = getOldUserData()
        const newUserData = getNewUserData()

        return JSON.stringify(oldUserData) !== JSON.stringify(newUserData)
    }

    const clearErrors = () => setError(null)

    const isChanges = getIsChanges()
    const isValid = name.length > 0

    const textFieldProps = { onFocus: clearErrors, isLoading: loadingUser || loading, disabled: loadingUser || loading }

    const mobileWarning = mobile && !isPossiblePhoneNumber(mobile) ?
        [intl.formatMessage({ id: "Dashboard.UserProfile.warningMobileP1" }), intl.formatMessage({ id: "Dashboard.UserProfile.warningMobileP2" })]
        : null;

    return (
        <Fragment>
            <EuiForm>
                <EuiFormRow label={<span>{intl.formatMessage({ id: "Dashboard.UserProfile.name" })} <span style={{ color: 'red' }} >*</span></span>} >
                    <EuiFieldText required name="name" value={name} onChange={e => setName(e.target.value)} maxLength={100} {...textFieldProps} disabled={textFieldProps.disabled || !canEditUser} />
                </EuiFormRow>

                <EuiFormRow label={intl.formatMessage({ id: "Dashboard.UserProfile.mobile" })} error={mobileWarning} isInvalid={!!mobileWarning} >
                    <PhoneInput
                        international
                        // defaultCountry="NO"
                        name="mobile"
                        value={mobile}
                        onChange={setMobile}
                        maxLength={20}
                        inputComponent={CustomPhoneInput}
                        isInvalid={!!mobileWarning}
                        {...textFieldProps}
                    />
                </EuiFormRow>

                <EuiSpacer />

                {loadingUser && (
                    <Fragment>
                        <EuiText color="secondary" >
                            <p><FormattedMessage id="Dashboard.UserProfile.loadingUserData" /></p>
                        </EuiText>

                        <EuiSpacer />
                    </Fragment>
                )}

                {loading && (
                    <Fragment>
                        <EuiText color="secondary" >
                            <p><FormattedMessage id="Dashboard.UserProfile.savingUserData" /></p>
                        </EuiText>

                        <EuiSpacer />
                    </Fragment>
                )}

                {isDiff && !loadingUser && !loading && isValid && (
                    <Fragment>
                        <EuiText color="warning" >
                            <FormattedMessage id="Dashboard.UserProfile.diffWarning" values={{ link: <EuiLink onClick={_handleSave} ><FormattedMessage id="Dashboard.UserProfile.diffWarningLinkTxt" /></EuiLink> }} />
                        </EuiText>

                        <EuiSpacer />
                    </Fragment>
                )}

                {(error || errorUser) && (
                    <Fragment>
                        <EuiText>
                            <EuiTextColor color="danger" >
                                {error && error.map(e => (
                                    <p key={e} >{e}</p>
                                ))}
                                {errorUser && <p><FormattedMessage id="Dashboard.UserProfile.getUserDataError" /></p>}
                            </EuiTextColor>
                        </EuiText>

                        <EuiSpacer />
                    </Fragment>
                )}

                {errorManufacturers && (
                    <Fragment>
                        <EuiText>
                            <EuiTextColor color="warning" >
                                <p><FormattedMessage id="Dashboard.UserProfile.errorManufacturerP1" /></p>
                            </EuiTextColor>
                            <ul>
                                {errorManufacturers.map(row => (
                                    <Fragment key={row.manufacturer.id} >
                                        <li>
                                            {row.manufacturer.name}{" - "}
                                            {row.error ?
                                                (
                                                    <EuiTextColor color="danger" >
                                                        <FormattedMessage id="Dashboard.UserProfile.errorManufacturerError" />
                                                    </EuiTextColor>

                                                )
                                                :
                                                (
                                                    <EuiTextColor color="secondary" >
                                                        <FormattedMessage id="Dashboard.UserProfile.errorManufacturerOk" />
                                                    </EuiTextColor>
                                                )}
                                        </li>

                                    </Fragment>
                                ))}
                            </ul>
                            <EuiTextColor color="warning" >
                                <p><FormattedMessage id="Dashboard.UserProfile.errorManufacturerP2" /></p>
                            </EuiTextColor>
                        </EuiText>

                        <EuiSpacer />
                    </Fragment>
                )}

                <EuiFormRow helpText={intl.formatMessage({ id: "Dashboard.UserProfile.helpText" })} >
                    <SaveButton onClick={_handleSave} loading={loading} error={error || errorManufacturers} disabled={(!isChanges || !isValid || loadingUser) && !errorManufacturers} >
                        <FormattedMessage id="Dashboard.UserProfile.saveButtonText" />
                    </SaveButton>
                </EuiFormRow>
            </EuiForm>
        </Fragment>
    );
}

export default UserProfile;
