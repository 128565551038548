import React, { Fragment, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiText,
} from '@elastic/eui';

import { addUserEmail } from '../../api/services/user'

const AddEmailAddress = ({ user, updateEmails, setUpdateEmails }) => {
    const intl = useIntl()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)

    const openModal = () => setIsModalVisible(true)
    const closeModal = () => {
        setEmail("")
        setIsModalVisible(false)

        if (isSuccess) {
            setIsSuccess(false)
            setUpdateEmails(!updateEmails)
        }
    }

    const handleAdd = async () => {
        setLoading(true)
        setError(null)

        try {
            await addUserEmail(user.id, email)

            setLoading(false)
            setIsSuccess(true)
        } catch (error) {
            setLoading(false)

            let errorKey = "general"

            switch (error.data.msgKey) {
                case "email_invalid":
                    errorKey = "email_invalid"
                    break;
                case "email_taken":
                    errorKey = "email_taken"
                    break;
                default:
                    errorKey = "general"
                    break;
            }

            setError(intl.formatMessage({ id: `Dashboard.AddEmailAddress.Errors.${errorKey}` }))
        }
    }

    const isValid = email.length > 0 && !error

    const handleKeyPress = e => {
        if (e.key === 'Enter' && isValid && !loading) {
            handleAdd()
        }
    }

    return (
        <Fragment>
            <EuiButton fill onClick={openModal} >
                <FormattedMessage id="Dashboard.AddEmailAddress.addEmailAddress" />
            </EuiButton>

            {isModalVisible && (
                <EuiOverlayMask>
                    <EuiModal onClose={closeModal} initialFocus="[name=email]" >
                        <EuiModalHeader>
                            <EuiModalHeaderTitle>
                                <FormattedMessage id="Dashboard.AddEmailAddress.header" />
                            </EuiModalHeaderTitle>
                        </EuiModalHeader>

                        <EuiModalBody>
                            {!isSuccess ?
                                (
                                    <EuiForm>
                                        <EuiFormRow
                                            label={intl.formatMessage({ id: "Dashboard.AddEmailAddress.email" })}
                                            isInvalid={error !== null}
                                            error={error ? [error] : null}
                                        >
                                            <EuiFieldText
                                                name="email"
                                                value={email}
                                                onFocus={_ => setError(null)}
                                                onChange={e => setEmail(e.target.value)}
                                                isInvalid={error !== null}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </EuiFormRow>
                                    </EuiForm>
                                ) : (
                                    <EuiText>
                                        <p>
                                            <FormattedMessage id="Dashboard.AddEmailAddress.successMessage" values={{ email }} />
                                        </p>
                                    </EuiText>
                                )}

                        </EuiModalBody>

                        <EuiModalFooter>
                            {!isSuccess ?
                                (
                                    <Fragment>
                                        <EuiButtonEmpty onClick={closeModal}>
                                            <FormattedMessage id="Dashboard.AddEmailAddress.cancel" />
                                        </EuiButtonEmpty>

                                        <EuiButton onClick={handleAdd} fill isDisabled={!isValid || loading} isLoading={loading} >
                                            <FormattedMessage id="Dashboard.AddEmailAddress.add" />
                                        </EuiButton>
                                    </Fragment>
                                ) : (
                                    <EuiButton onClick={closeModal} fill >
                                        <FormattedMessage id="Dashboard.AddEmailAddress.close" />
                                    </EuiButton>
                                )}
                        </EuiModalFooter>
                    </EuiModal>
                </EuiOverlayMask>
            )}
        </Fragment>
    );
}

export default AddEmailAddress;
