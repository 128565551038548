import React, { useState, useEffect } from 'react';
import {
    EuiFlexGroup,
    EuiSpacer,
} from '@elastic/eui';

const lowResSrc = "https://firebasestorage.googleapis.com/v0/b/octaos-id.appspot.com/o/background-image-low-res.png?alt=media&token=63b52ca8-2804-46cb-8ee9-84f35d351cbd"
const highResSrc = "https://firebasestorage.googleapis.com/v0/b/octaos-id.appspot.com/o/background-image%403x.png?alt=media&token=09a7a90a-fe5b-4f56-9faa-8e425d7678ac"

const ImagePage = ({ children, bottom }) => {
    const [src, setSrc] = useState(null)

    useEffect(() => {
        const imageLoader = new Image();
        imageLoader.src = highResSrc;

        imageLoader.onload = () => {
            setSrc(highResSrc)
        }
    }, [])

    return (
        <div>
            <EuiFlexGroup
                style={{
                    height: "60vh",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // filter: "grayscale(100%)",
                    backgroundSize: "cover",
                    backgroundImage: `url(${src || lowResSrc})`
                }}
                alignItems="center"
                justifyContent="center"
                gutterSize="none"
            >
                <div>
                    <div style={{ maxWidth: 400 }} >
                        {children}
                    </div>
                </div>
            </EuiFlexGroup>

            <EuiSpacer size="xxl" />

            {bottom}
        </div>
    )
}

export default ImagePage;
