import React, { Fragment } from 'react';
import {
    EuiImage,
    EuiFlexGroup,
    EuiFlexItem,
    EuiText,
    EuiLink,
} from '@elastic/eui';
import md5 from 'md5';
import { FormattedMessage } from 'react-intl';

const UserProfilePicture = ({ user }) => {
    return (
        <Fragment>
            <EuiFlexGroup gutterSize="l" >
                <EuiFlexItem grow={false}>
                    <EuiImage
                        size="m"
                        hasShadow
                        // caption="Random nature image"
                        alt="Profile picture"
                        url={`https://www.gravatar.com/avatar/${md5(user ? user.email : "")}?d=mp&s=2048`}
                    />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                    <EuiText grow={false} >
                        <p>
                            <FormattedMessage id="Dashboard.UserProfilePicture.editPicture" />{' '}
                            <EuiLink href="https://gravatar.com/" target="_blank">
                                Gravatar
                            </EuiLink>
                        </p>
                        <p>
                            <EuiLink href="https://en.gravatar.com/support/what-is-gravatar/" target="_blank">
                                <FormattedMessage id="Dashboard.UserProfilePicture.whatIsGravatar" />
                            </EuiLink>
                        </p>
                    </EuiText>
                </EuiFlexItem>
            </EuiFlexGroup>
        </Fragment>
    );
}

export default UserProfilePicture;
