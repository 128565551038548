import React, { Fragment, useState } from 'react';
import {
    EuiPage,
    EuiPageBody,
    EuiPageContent_Deprecated as EuiPageContent,
    EuiPageContentBody_Deprecated as EuiPageContentBody,
    EuiPageContentHeader_Deprecated as EuiPageContentHeader,
    EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection,
    EuiTitle,
    EuiTextColor,
    EuiSpacer,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router';

import { setPassword } from '../../api/services/user';
import PasswordForm from '../../components/PasswordForm';
import { isAuthenticated } from '../../helpers/auth';

const SetPassword = () => {
    const history = useHistory();

    if (!isAuthenticated()) history.push("/")

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleSetPassword = newPassword => {
        setLoading(true)
        setError(null)

        setPassword(newPassword)
            .then(({ data }) => {
                if (!data.success) {
                    setError("general")
                    setLoading(false)
                } else {
                    history.push("/")
                }
            })
            .catch(error => {
                if (error?.status === 403) {
                    setError("api_access")
                } else {
                    setError("general")
                }

                setLoading(false)
            })
    }

    return (
        <Fragment>
            <EuiPage>
                <EuiPageBody>
                    <EuiPageContent verticalPosition="center" horizontalPosition="center">
                        <EuiPageContentHeader>
                            <EuiPageContentHeaderSection>
                                <EuiTitle>
                                    <h2><FormattedMessage id="SetPassword.title" /></h2>
                                </EuiTitle>
                            </EuiPageContentHeaderSection>
                        </EuiPageContentHeader>
                        <EuiPageContentBody>
                            {error && (
                                <Fragment>
                                    <EuiTitle>
                                        <h4>
                                            <EuiTextColor color="danger" >
                                                <FormattedMessage id={`SetPassword.Errors.${error}`} />
                                            </EuiTextColor>
                                        </h4>
                                    </EuiTitle>

                                    <EuiSpacer />
                                </Fragment>
                            )}

                            <PasswordForm
                                isSetPassword
                                loading={loading}
                                error={error}
                                resetError={() => setError(null)}
                                onClick={handleSetPassword}
                            />

                            {/* Forces text field to be larger */}
                            <div style={{ width: 400 }} />

                        </EuiPageContentBody>
                    </EuiPageContent>
                </EuiPageBody>
            </EuiPage>

        </Fragment>
    );
}

export default SetPassword;
