import React, { Fragment } from 'react';
import { Route } from "react-router-dom"

import MainNavigator from '../MainNaviagator';

const AnonRoute = ({ path, component, shrink = true, currentLanguageCode, onLanguageChange, ...props }) => {
    return (
        <Fragment>
            <MainNavigator currentLanguageCode={currentLanguageCode} onLanguageChange={onLanguageChange} />

            <div
                style={{
                    marginTop: 81,
                    marginLeft: shrink ? 32 : 0,
                    marginRight: shrink ? 32 : 0,
                    marginBottom: 32,
                }}
            >
                <Route path={path} component={({ ...props }) => component({ ...props })} {...props} />
            </div>
        </Fragment>
    );
}

export default AnonRoute;