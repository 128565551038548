import axios from 'axios';
import { getToken, getMid, isAuthenticated, deleteToken } from '../helpers/auth';
import { getCurrentLanguage } from '../helpers/storage';
import { showError } from '../helpers/showError';
import { getApiUrl } from '../helpers/utils';

const getAcceptLanguage = currentLanguage => {
    switch (currentLanguage) {
        case "nb":
            return "nb-NO";
        case "en":
            return "en-US";
        default:
            return "en-US";
    }
}

const defaultValues = {};
defaultValues.baseURL = getApiUrl();
defaultValues.headers = {
    'Content-Type': 'application/json',
};

const client = axios.create(defaultValues);

const request = (options, xMid = null, useAuth = true) => {
    const parts = window.location.hostname.split('.');
    const subdomain = parts.shift();

    if (subdomain && subdomain === "id-dev") {
        client.defaults.baseURL = "https://api-dev.octaos.com";
    }

    if (isAuthenticated() && useAuth) {
        client.defaults.headers.common.Authorization = `Bearer ${getToken()}`;
        // client.defaults.params = { token: getToken() }
        client.defaults.headers["x-mid"] = xMid ? xMid : getMid();
    }

    const currentLanguage = getCurrentLanguage();

    if (currentLanguage) {
        if (!options.headers) options.headers = {};

        options.headers['X-Accept-Language'] = getAcceptLanguage(currentLanguage);
    }

    const onSuccess = response => response;
    const onError = error => {
        if (
            error.response &&
            error.response.status === 401 &&
            (error.response.data.msgKey === 'token_expired' ||
                error.response.data.msgKey === 'invalid_token')
        ) {
            deleteToken()
            window.location.reload()
        }

        if (error.response) {
            console.error('Status:', error.response.status);
            console.error('Data:', error.response.data);
            console.error('Headers:', error.response.headers);
        } else {
            console.error('Error Message:', error.message);
        }

        if (error.response && error.response.status === 429) {
            showError("Too many requests!");
        }

        return Promise.reject(error.response || error.message);
    };

    return client(options)
        .then(onSuccess)
        .catch(onError);
};

export default request;
