import React, { useState, useEffect } from 'react';
import {
    EuiButton,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

const SaveButton = ({ onClick, loading, disabled, error, children }) => {
    const [showSaved, setShowSaved] = useState(false)
    const [showError, setShowError] = useState(false)
    const [isInit, setIsInit] = useState(true)

    useEffect(() => {
        if (!loading && !isInit) {
            if (error) {
                if (!showSaved) setShowError(true)
            } else {
                if (!showError) setShowSaved(true)
            }

            setTimeout(() => {
                setShowSaved(false)
                setShowError(false)
                setIsInit(true)
            }, 3000)
        }

        if (loading) {
            setIsInit(false)
        }
    }, [setShowSaved, showSaved, showError, loading, isInit, error])

    const iconType = showSaved ? "check" : showError ? "cross" : "save"

    return (
        <EuiButton
            fill
            type="submit"
            iconType={iconType}
            onClick={onClick}
            isDisabled={disabled || loading}
            isLoading={loading}
            color={showError ? "danger" : "primary"}
        >
            {loading && <FormattedMessage id="SaveButton.saving" />}
            {showSaved && <FormattedMessage id="SaveButton.saved" />}
            {showError && <FormattedMessage id="SaveButton.error" />}
            {!loading && !showSaved && !showError && children}
        </EuiButton>
    )
}

export default SaveButton;
