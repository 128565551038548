import React, { Fragment } from 'react';

const UseOfCookies = () => {
    return (
        <Fragment>
            <div
                style={{
                    position: 'fixed',
                    top: 48,
                    bottom: 40,
                    left: 0,
                    right: 0,
                }}
            >
                <iframe
                    title="Cookies Policy"
                    src="https://www.termsfeed.com/cookies-policy/7dcf156bef993ab4f5fad98bee93df8a"
                    style={{
                        border: 'none',
                        height: '100%',
                        width: '100%',
                    }}
                />
            </div>
        </Fragment>
    );
}

export default UseOfCookies;
