import React, { Fragment, useState } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiText, EuiSpacer } from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import UserProfile from './UserProfile';
import UserProfilePicture from './UserProfilePicture';
import EmailAddresses from './EmailAddresses';
import AddEmailAddress from './AddEmailAddress';
import MyAccounts from './MyAccounts';
import DeleteAccount from './DeleteAccount';

const Dashboard = ({ user }) => {
    const [updateEmails, setUpdateEmails] = useState(false)

    return (
        <Fragment>
            <EuiText>
                <h2><FormattedMessage id="Dashboard.Index.userProfile" /></h2>
            </EuiText>

            <EuiSpacer />

            <EuiFlexGroup>
                <EuiFlexItem>
                    <UserProfile user={user} />
                </EuiFlexItem>
                <EuiFlexItem>
                    <UserProfilePicture user={user} />
                </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer />

            <EuiText>
                <h2><FormattedMessage id="Dashboard.Index.emailAddresses" /></h2>
            </EuiText>
            <EmailAddresses user={user} updateEmails={updateEmails} setUpdateEmails={setUpdateEmails} />

            <AddEmailAddress user={user} updateEmails={updateEmails} setUpdateEmails={setUpdateEmails} />

            <EuiSpacer />

            <EuiText>
                <h2><FormattedMessage id="Dashboard.Index.myAccounts" /></h2>
            </EuiText>
            <MyAccounts />

            <EuiSpacer />

            <EuiText>
                <h2><FormattedMessage id="Dashboard.Index.deleteAccount" /></h2>
            </EuiText>
            <DeleteAccount />
        </Fragment>
    );
}

export default Dashboard;
