import React, { Fragment } from 'react';
import {
    EuiFormRow,
} from '@elastic/eui';
import { useIntl, FormattedMessage } from 'react-intl';

import EuiLinkButton from '../../components/EuiLinkButton'

const MyAccounts = () => {
    const intl = useIntl()

    return (
        <Fragment>
            <EuiFormRow fullWidth helpText={intl.formatMessage({ id: "Dashboard.MyAccounts.helpText" })} >
                <span></span>
            </EuiFormRow>
            <EuiLinkButton fill to="/myAccounts" >
                <FormattedMessage id="Dashboard.MyAccounts.buttonText" />
            </EuiLinkButton>
        </Fragment>
    );
}

export default MyAccounts;
