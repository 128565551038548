import React, { useState } from 'react';
import {
    EuiFormRow,
    EuiFieldText,
    EuiButtonIcon,
    EuiText,
    EuiTextAlign,
    EuiPanel,
    EuiSpacer,
} from '@elastic/eui';
import { FormattedMessage, useIntl } from 'react-intl';

import ImagePage from '../../components/ImagePage';
import { resetPassword } from '../../api/services/user'
import EuiLinkButton from '../../components/EuiLinkButton';


const ResetPassword = () => {
    const intl = useIntl()

    const [username, setUsername] = useState("")
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [resData, setResData] = useState(null)

    const handleResetPassword = () => {
        setLoading(true)
        resetPassword(username)
            .then(res => {
                setResData(res.data)
                setUsername("")
            })
            .catch(e => {
                const key = e.status === 404 ? "emailNotFound" : "general"
                const msg = intl.formatMessage({ id: `ResetPassword.Errors.${key}` })

                setError(msg)
            })
            .then(() => setLoading(false))
    }

    const handleKeyPress = e => {
        if (e.key === "Enter" && username.length > 0 && !loading) {
            handleResetPassword()
        }
    }

    return (
        <ImagePage
            bottom={
                <EuiText>
                    <EuiTextAlign textAlign="center" >
                        <h2><FormattedMessage id="ResetPassword.header" /></h2>

                        <EuiSpacer />

                        <EuiLinkButton to="/login" >
                            <FormattedMessage id="ResetPassword.goToLogin" />
                        </EuiLinkButton>
                    </EuiTextAlign>
                </EuiText>
            }
        >
            {resData && resData.success ?
                (
                    <EuiPanel>
                        <EuiText>
                            <EuiTextAlign textAlign="center" >
                                <h3><FormattedMessage id="ResetPassword.success" /></h3>
                                <p><FormattedMessage id="ResetPassword.successMessage" /></p>
                            </EuiTextAlign>
                        </EuiText>
                    </EuiPanel>
                )
                :
                (
                    <EuiFormRow isInvalid={error !== null} label={intl.formatMessage({ id: "ResetPassword.usernameLabel" })} error={error} >
                        <EuiFieldText
                            style={{ textAlign: 'center' }}
                            placeholder={intl.formatMessage({ id: "ResetPassword.usernamePlaceholder" })}
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            onFocus={() => setError(null)}
                            isInvalid={error !== null}
                            isLoading={loading}
                            disabled={loading}
                            onKeyPress={handleKeyPress}
                            append={!loading ?
                                <EuiButtonIcon
                                    iconType="arrowRight"
                                    disabled={username.length <= 0 || loading}
                                    onClick={handleResetPassword}
                                    aria-label="ResetPassword"
                                />
                                :
                                null
                            }
                        />
                    </EuiFormRow>
                )}

            {/* Forces text field to be larger */}
            <div style={{ width: 400 }} />
        </ImagePage>
    );
}

export default ResetPassword;
