const goBackToKey = "goBackTo"

export const setGoBackTo = (goBackTo) => {
    localStorage.setItem(goBackToKey, goBackTo);
};

export const isGoBackTo = () => !!localStorage.getItem(goBackToKey);

export const deleteGoBackTo = () => {
    localStorage.removeItem(goBackToKey);
};

export const getGoBackTo = () => localStorage.getItem(goBackToKey);

const currentLanguageKey = "currentLanguage"

export const setCurrentLanguage = code => {
    localStorage.setItem(currentLanguageKey, code);
};

export const getCurrentLanguage = () => {
    const stored = localStorage.getItem(currentLanguageKey)

    if (stored) return stored

    return "en"
};
