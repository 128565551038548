import { initializeApp } from "firebase/app";
import { getAnalytics, setUserId, setUserProperties } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD6DElnYHgq1ej9s7n2pIToUrCFVj06PVc",
    authDomain: "octaos-id.firebaseapp.com",
    databaseURL: "https://octaos-id.firebaseio.com",
    projectId: "octaos-id",
    storageBucket: "octaos-id.appspot.com",
    messagingSenderId: "898255018158",
    appId: "1:898255018158:web:6418e5887ea6a58424b53f",
    measurementId: "G-56G1502K8W",
};

initializeApp(firebaseConfig);

let analytics;

if (process.env.REACT_APP_OSC_API_URL !== "https://api-dev.octaos.com" && window.location.hostname !== "localhost") {
    analytics = getAnalytics();
}

export { analytics, setUserId, setUserProperties };
