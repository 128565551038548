import Cookies from 'js-cookie';

const tokenKey = "token"
const midKey = "mid"

export const setToken = (token, mid) => {
  Cookies.set(tokenKey, token, { expires: 30 });
  Cookies.set(midKey, mid, { expires: 30 });
};

export const isAuthenticated = () => !!Cookies.get(tokenKey) && !!Cookies.get(midKey);

export const deleteToken = () => {
  Cookies.remove(tokenKey);
  Cookies.remove(midKey);
};

export const getToken = () => Cookies.get(tokenKey);

export const getMid = () => Cookies.get(midKey);

