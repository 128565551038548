import request from '../request';

export const login = body => request({
  url: '/auth/login/identifyManufacturer',
  method: 'POST',
  headers: {},
  data: body,
});

export const logout = () => request({
  url: '/auth/logout',
  method: 'POST',
});

export const twoFactorConfirm = (email, code, token) => request({
  url: '/auth/octaos-id-app/confirm?useSocket=false',
  method: 'POST',
  headers: {},
  data: { email, code, token },
});

