import React, { Fragment, useState } from 'react';
import {
    EuiSpacer,
    EuiPage,
    EuiPageBody,
    EuiPageContent_Deprecated as EuiPageContent,
    EuiPageContentBody_Deprecated as EuiPageContentBody,
    EuiPageContentHeader_Deprecated as EuiPageContentHeader,
    EuiPageContentHeaderSection_Deprecated as EuiPageContentHeaderSection,
    EuiTitle,
    EuiText,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import { resetPasswordConfirm } from '../../api/services/user';

import EuiLinkButton from '../../components/EuiLinkButton';
import PasswordForm from '../../components/PasswordForm';

const ResetPasswordConfirm = ({ match: { params: { code } } }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleResetPassword = newPassword => {
        setLoading(true)
        setError(null)

        resetPasswordConfirm(code, newPassword)
            .then(({ data }) => {
                if (!data.success) {
                    setError("general")
                } else {
                    setIsSuccess(true)
                }
            })
            .catch(error => {
                if (error?.status === 404 && error?.data?.msgKey === "invalid_reset_token") {
                    setError("invalidResetToken")
                } else {
                    setError("general")
                }
            })
            .then(() => setLoading(false))
    }

    return (
        <Fragment>
            <EuiPage>
                <EuiPageBody>
                    <EuiPageContent verticalPosition="center" horizontalPosition="center">
                        <EuiPageContentHeader>
                            <EuiPageContentHeaderSection>
                                <EuiTitle>
                                    <h2><FormattedMessage id="ResetPasswordConfirm.title" /></h2>
                                </EuiTitle>
                            </EuiPageContentHeaderSection>
                        </EuiPageContentHeader>
                        <EuiPageContentBody>
                            {isSuccess ?
                                (
                                    <Fragment>
                                        <EuiText>
                                            <h3><FormattedMessage id="ResetPasswordConfirm.success" /></h3>
                                        </EuiText>

                                        <EuiSpacer />

                                        <EuiLinkButton to="/login" >
                                            <FormattedMessage id="ResetPasswordConfirm.goToLogin" />
                                        </EuiLinkButton>
                                    </Fragment>
                                )
                                :
                                (
                                    <Fragment>
                                        {error && error === "invalidResetToken" ?
                                            (
                                                <Fragment>
                                                    <EuiText>
                                                        <h3><FormattedMessage id="ResetPasswordConfirm.Errors.invalidResetToken" /></h3>
                                                    </EuiText>

                                                    <EuiSpacer />

                                                    <EuiLinkButton to="/resetPassword" >
                                                        <FormattedMessage id="ResetPasswordConfirm.goToResetPassword" />
                                                    </EuiLinkButton>
                                                </Fragment>
                                            )
                                            :
                                            (
                                                <PasswordForm
                                                    loading={loading}
                                                    error={error}
                                                    resetError={() => setError(null)}
                                                    onClick={handleResetPassword}
                                                />
                                            )}
                                    </Fragment>
                                )}

                            {/* Forces text field to be larger */}
                            <div style={{ width: 400 }} />

                        </EuiPageContentBody>
                    </EuiPageContent>
                </EuiPageBody>
            </EuiPage>

        </Fragment>
    );
}

export default ResetPasswordConfirm;
