import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom"

import { logout } from '../api/services/auth'
import { deleteToken } from '../helpers/auth'

const Logout = () => {
    // const [loading, setLoading] = useState(false)
    const [redirect, setRedirect] = useState(false)

    const handleLogout = () => {
        logout()

        deleteToken()

        localStorage.removeItem("user")

        setRedirect(true)
    }

    useEffect(handleLogout)

    if (redirect) return <Redirect to="/" />

    return (
        <div />
    )
}

export default Logout;
