import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import {
    EuiHeader,
    EuiHeaderSectionItem,
    EuiHeaderLogo,
    EuiHeaderLinks,
    EuiHeaderLink,
    EuiHeaderSection,
    EuiHorizontalRule,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import { isGoBackTo, getGoBackTo, deleteGoBackTo } from '../../helpers/storage'
import HeaderSettings from './HeaderSettings';

const MainNavigator = ({ isAuth, currentLanguageCode, onLanguageChange }) => {
    const history = useHistory()

    const [isDesktop, setIsDesktop] = useState(true);

    useEffect(() => {
        const handleResize = () => setIsDesktop(window.innerWidth > 760)

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    })

    const isModifiedEvent = (event) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

    const isLeftClickEvent = (event) => event.button === 0

    const onClick = (event, to) => {
        if (event.defaultPrevented) {
            return
        }

        if (event.target.getAttribute('target')) {
            return
        }

        if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
            return
        }

        event.preventDefault()

        history.push(to)
    }

    const NavButton = ({ text, to, iconType }) => {
        return (
            <EuiHeaderLink iconType={iconType} onClick={(e) => onClick(e, to)} isActive={history.location.pathname === to} >
                <FormattedMessage id={`MainNavigator.${text}`} />
            </EuiHeaderLink>
        )
    }

    const GoBackToButton = () => {
        const show = isGoBackTo()

        if (!show) return null

        const goBackTo = getGoBackTo()
        let url

        try {
            url = new URL(goBackTo)
        } catch (error) {
            console.log(error)
            deleteGoBackTo()
            return null
        }

        return (
            <EuiHeaderLink href={goBackTo} >
                <FormattedMessage id="MainNavigator.goBackTo" values={{ url: url.hostname }} />
            </EuiHeaderLink>
        )
    }

    const MainLinks = () => (
        <Fragment>
            {!isAuth ? (
                <Fragment>
                    <NavButton text="login" to="/login" />
                    <NavButton text="register" to="/register" />
                    <NavButton text="faq" to="/faq" />
                    <GoBackToButton />
                </Fragment>
            )
                :
                (
                    <Fragment>
                        <NavButton text="dashboard" to="/dashboard" />
                        <NavButton text="faq" to="/faq" />
                        <NavButton text="settings" to="/settings" />
                        <GoBackToButton />
                    </Fragment>
                )
            }
        </Fragment>
    )

    const HeaderSettingsLinks = () => <HeaderSettings currentLanguageCode={currentLanguageCode} onLanguageChange={onLanguageChange} />

    const LogoutLinks = () => <NavButton text="logout" to="/logout" iconType="exit" />

    return (
        <EuiHeader style={{ position: 'fixed', top: 0, width: '100%' }} >
            <EuiHeaderSection>
                <EuiHeaderSectionItem border="right">
                    <EuiHeaderLogo
                        iconType="https://ossdatadev.blob.core.windows.net/manufacturers-configs/2F007C66-BB3C-4808-8589-A010F085A80D/domains/E4FD90F9-5663-40FE-6D1A-08D62918390D/icon.png"
                        href="/"
                    >
                        <FormattedMessage id="MainNavigator.header" />{process.env.REACT_APP_OSC_API_URL === "https://api-dev.octaos.com" && " (dev)"}
                    </EuiHeaderLogo>
                </EuiHeaderSectionItem>
            </EuiHeaderSection>

            {isDesktop ?
                (
                    <Fragment>
                        <EuiHeaderSection grow >
                            <EuiHeaderLinks><MainLinks /></EuiHeaderLinks>
                        </EuiHeaderSection>

                        <EuiHeaderSection>
                            <EuiHeaderLinks>
                                <HeaderSettingsLinks />
                            </EuiHeaderLinks>
                        </EuiHeaderSection>

                        {isAuth && (
                            <EuiHeaderSection>
                                <EuiHeaderLinks><LogoutLinks /></EuiHeaderLinks>
                            </EuiHeaderSection>
                        )}
                    </Fragment>
                )
                :
                (
                    <EuiHeaderSection side="right" >
                        <EuiHeaderLinks>
                            <MainLinks />
                            <EuiHorizontalRule margin="s" />
                            <div style={{ paddingLeft: 8, paddingRight: 8 }} >
                                <HeaderSettingsLinks />
                            </div>
                            {isAuth && (
                                <Fragment>
                                    <EuiHorizontalRule margin="s" />
                                    <LogoutLinks />
                                </Fragment>
                            )}
                        </EuiHeaderLinks>
                    </EuiHeaderSection>
                )}


        </EuiHeader>
    )
}

export default MainNavigator;
