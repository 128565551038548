import React from 'react';
import { EuiText } from '@elastic/eui';
import CookieConsent from "react-cookie-consent";
import { FormattedMessage } from 'react-intl';

const MyCookieConsent = () => {
    const buttonText = (
        <span className="euiButton__content" >
            <span className="euiButton__text" >
                <FormattedMessage id="CookieConsent.buttonText" />
            </span>
        </span>
    )

    return (
        <CookieConsent
            style={{ zIndex: 999999 }}
            buttonText={buttonText}
            buttonClasses="euiButton euiButton--primary"
        >
            <EuiText>
                <FormattedMessage id="CookieConsent.message" />
            </EuiText>
        </CookieConsent>
    );
}

export default MyCookieConsent;
