const _getRights = () => {
	const savedUser = localStorage.getItem("user")

	if (savedUser) {
		const user = JSON.parse(savedUser)

		return user.accessRights
	}

	return null
}

/**
 * Checks if user has permission to passed right
 * @param {string} expectedRight
 * @returns {boolean}
 */
export const hasRight = (expectedRight) => {
	try {
		const rightsFromStorage = _getRights();
		const regex = new RegExp(`\\b${expectedRight}\\b`);
		return regex.test(rightsFromStorage);
	} catch (e) {
		return false;
	}
};

/**
 * Checks if user has any of passed permissions
 * @param {Array<string>} expectedRights
 * @returns {boolean}
 */
export const hasRightsOR = (expectedRights) => {
	try {
		const rightsFromStorage = _getRights();

		return expectedRights.some(right => {
			const regex = new RegExp(`\\b${right}\\b`);
			return regex.test(rightsFromStorage);
		});
	} catch (e) {
		return false;
	}
};

/**
 * Checks if user has all of passed permissions
 * @param {Array<string>} expectedRights
 * @returns {boolean}
 */
export const hasRightsAND = (expectedRights) => {
	try {
		const rightsFromStorage = _getRights();

		return expectedRights.every(right => {
			const regex = new RegExp(`\\b${right}\\b`);
			return regex.test(rightsFromStorage);
		});
	} catch (e) {
		return false;
	}
};

