import React, { Fragment } from 'react';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import useApi from '../../api/useApi'
import { setAccountEmail } from '../../api/services/user'

const UpdateEmailModal = ({ user, manufacturerUser, isModalVisible, closeModal }) => {
    const apiRequest = () => setAccountEmail(user.id, user.email, manufacturerUser.guid)
    const [loading, error, result] = useApi(apiRequest, [user, isModalVisible, manufacturerUser], user && manufacturerUser && isModalVisible)

    const isSuccess = !loading && !error && result && result.email === user.email

    const handleClose = () => {
        closeModal(isSuccess)
    }

    return (
        <Fragment>
            {isModalVisible && (
                <EuiOverlayMask>
                    <EuiModal onClose={handleClose} >
                        <EuiModalHeader>
                            <EuiModalHeaderTitle><FormattedMessage id="MyAccounts.UpdateEmailModal.title" /></EuiModalHeaderTitle>
                        </EuiModalHeader>

                        <EuiModalBody>
                            {isSuccess && (
                                <EuiText>
                                    <h3>
                                        <EuiTextColor color="secondary" >
                                            <FormattedMessage id="MyAccounts.UpdateEmailModal.successMessage" />
                                        </EuiTextColor>
                                    </h3>
                                </EuiText>
                            )}

                            {error && (
                                <EuiText>
                                    <h3>
                                        <EuiTextColor color="danger" >
                                            <FormattedMessage id="MyAccounts.UpdateEmailModal.errorMessage" />
                                        </EuiTextColor>
                                    </h3>
                                </EuiText>
                            )}
                        </EuiModalBody>

                        <EuiModalFooter>
                            {!isSuccess && <EuiButtonEmpty onClick={handleClose}><FormattedMessage id="MyAccounts.UpdateEmailModal.cancel" /></EuiButtonEmpty>}

                            <EuiButton onClick={handleClose} fill isDisabled={loading} isLoading={loading} >
                                <FormattedMessage id={`MyAccounts.UpdateEmailModal.${loading ? "loading" : "close"}`} />
                            </EuiButton>
                        </EuiModalFooter>
                    </EuiModal>
                </EuiOverlayMask>
            )}
        </Fragment>
    );
}

export default UpdateEmailModal;
