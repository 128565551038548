import React, { Fragment } from 'react';
import { EuiText, EuiTextAlign } from '@elastic/eui';
import { FormattedMessage, useIntl } from 'react-intl';

import useApi from '../../api/useApi';
import { addUserEmailConfirm } from '../../api/services/user';
import EuiLinkButton from '../../components/EuiLinkButton';
import OctaosLoader from '../../components/OctaosLoader';

const VerifyEmail = ({ match: { params: { code } } }) => {
    const intl = useIntl()
    const [loading, error, res] = useApi(() => addUserEmailConfirm(code))

    const hasExpired = error?.status === 410
    const isInvalid = error?.status === 403
    const unknownError = error && !hasExpired && !isInvalid
    const success = res?.success
    const connectedManufacturers = res?.connectedManufacturers

    return (
        <Fragment>
            <div style={{ height: 100 }} />

            {loading && <OctaosLoader text={intl.formatMessage({ id: "VerifyEmail.loaderText" })} />}

            <EuiText>
                <EuiTextAlign textAlign="center" >
                    {hasExpired && <h2><FormattedMessage id="VerifyEmail.Errors.hasExpired" /></h2>}
                    {isInvalid && <h2><FormattedMessage id="VerifyEmail.Errors.isInvalid" /></h2>}
                    {(unknownError || (!loading && !error && !success)) && <h2><FormattedMessage id="VerifyEmail.Errors.unknownError" /></h2>}

                    {success && (
                        <Fragment>
                            <h2><FormattedMessage id="VerifyEmail.success" /></h2>
                            {connectedManufacturers && connectedManufacturers.length > 0 ?
                                (
                                    <Fragment>
                                        <p><FormattedMessage id="VerifyEmail.connectedManufacturers1" /></p>
                                        <ul>
                                            {connectedManufacturers.map(row => (
                                                <li key={row.manufacturerName} >
                                                    {row.manufacturerName}
                                                </li>
                                            ))}
                                        </ul>
                                        <p><FormattedMessage id="VerifyEmail.connectedManufacturers2" /></p>
                                    </Fragment>
                                )
                                :
                                (
                                    <p><FormattedMessage id="VerifyEmail.noConnectedManufacturers" /></p>
                                )}
                        </Fragment>
                    )}

                    {!loading && (
                        <EuiLinkButton to="/" ><FormattedMessage id="VerifyEmail.goBack" /></EuiLinkButton>
                    )}
                </EuiTextAlign>
            </EuiText>
        </Fragment>
    );
}

export default VerifyEmail;
