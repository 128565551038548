import request from '../request';

export const getUser = () => request({
    url: '/user',
    method: 'GET',
});

export const getUserAll = () => request({
    url: '/user/all',
    method: 'GET',
});

export const getUserAttributes = id => request({
    url: `/user/${id}/attribute`,
    method: 'GET',
});

export const getUserAttribute = (id, attribute) => request({
    url: `/user/${id}/attribute/${attribute}`,
    method: 'GET',
});

export const addUserAttribute = (id, attribute, value) => request({
    url: `/user/${id}/attribute/${attribute}`,
    method: 'POST',
    data: { value }
});

export const updateUserAttribute = (id, attribute, value) => request({
    url: `/user/${id}/attribute/${attribute}`,
    method: 'PUT',
    data: { value }
});

export const removeUserAttribute = (id, attribute) => request({
    url: `/user/${id}/attribute/${attribute}`,
    method: 'DELETE',
});

export const userSearch = (q = null) => request({
    url: q ? `/user/search?q=${q}` : '/user/search',
    method: 'GET',
});

export const setAccountDetails = (accountId, data) => request({
    url: `/user/${accountId}/all`,
    method: 'PATCH',
    data,
});

export const getUserEmails = id => request({
    url: `/user/${id}/email`,
    method: 'GET',
});

export const addUserEmail = (id, email) => request({
    url: `/user/${id}/email`,
    method: 'POST',
    data: { newEmail: email }
});

export const addUserEmailResend = (id, email) => request({
    url: `/user/${id}/email/resend`,
    method: 'POST',
    data: { newEmail: email }
});

export const setUserEmail = (id, email) => request({
    url: `/user/${id}/email`,
    method: 'PUT',
    data: { newEmail: email }
});

export const deleteUserEmail = (id, email) => request({
    url: `/user/${id}/email`,
    method: 'DELETE',
    data: { email }
});

export const addUserEmailConfirm = code => request({
    url: `/user/email/confirm`,
    method: 'POST',
    data: { code }
}, null, false);

export const getUserManufacturers = id => request({
    url: `/user/${id}/manufacturer`,
    method: 'GET',
});

export const createByEmail = (email) => request({
    url: '/user/create-by-email',
    method: 'POST',
    data: { email }
});

export const createByEmailAll = (email) => request({
    url: '/user/create-by-email-all',
    method: 'POST',
    data: { email }
});

export const updatePassword = (oldPassword, newPassword) => request({
    url: '/user/password',
    method: 'PUT',
    data: { oldPassword, newPassword }
});

export const setPassword = newPassword => request({
    url: '/user/password',
    method: 'PUT',
    data: { newPassword }
});

export const resetPassword = username => request({
    url: '/user/password/reset',
    method: 'POST',
    data: { username }
});

export const resetPasswordConfirm = (resetToken, password) => request({
    url: '/user/password/reset',
    method: 'PUT',
    data: { resetToken, password }
});

export const setAccountEmail = (id, email, xMid) => request({
    url: `/user/${id}`,
    method: 'PATCH',
    data: { email, address: {} },
}, xMid);

export const activateAccount = activationCode => request({
    url: '/user/activate',
    method: 'POST',
    data: { activationCode, directLogin: true }
});

export const deleteAccount = password => request({
    url: '/user/delete',
    method: 'POST',
    data: { password },
});

