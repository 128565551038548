import React, { Fragment, useState } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldPassword,
    EuiSpacer,
} from '@elastic/eui';
import { FormattedMessage, useIntl } from 'react-intl';

import { updatePassword } from '../../api/services/user'
import SaveButton from '../../components/SaveButton';

const UpdatePassword = () => {
    const intl = useIntl()

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [newPassword2, setNewPassword2] = useState("")

    const handleUpdatePassword = () => {
        setLoading(true)
        setError(null)

        updatePassword(oldPassword, newPassword)
            .then(({ data }) => {
                if (!data.success) {
                    setError(data.msgKey === "old_password_wrong" ? "oldPasswordWrong" : "general")
                } else {
                    setOldPassword("")
                    setNewPassword("")
                    setNewPassword2("")
                }
            })
            .catch(error => setError("general"))
            .then(() => setLoading(false))
    }

    const resetError = () => setError(null)

    const isValid = oldPassword.length >= 0 && newPassword.length >= 8 && newPassword2.length >= 8 && newPassword === newPassword2

    const isOldPasswordError = error === "oldPasswordWrong"
    const isGeneralError = error === "general"
    const isRepeatePasswordError = newPassword.length >= 8 && newPassword2.length >= 8 && newPassword !== newPassword2

    const errorText = error ? intl.formatMessage({ id: `Settings.Errors.${error}` }) : null

    return (
        <Fragment>
            <EuiForm>
                <EuiFormRow
                    label={intl.formatMessage({ id: "Settings.oldPassword" })}
                    error={errorText}
                    isInvalid={isOldPasswordError || isGeneralError}
                >
                    <EuiFieldPassword
                        isInvalid={isOldPasswordError}
                        value={oldPassword}
                        onChange={e => setOldPassword(e.target.value)}
                        onFocus={resetError}
                    />
                </EuiFormRow>

                <EuiFormRow
                    label={intl.formatMessage({ id: "Settings.newPassword" })}
                    helpText={intl.formatMessage({ id: "Settings.newPasswordHelpText" })}
                >
                    <EuiFieldPassword
                        value={newPassword}
                        onChange={e => setNewPassword(e.target.value)}
                        onFocus={resetError}
                    />
                </EuiFormRow>

                <EuiFormRow
                    label={intl.formatMessage({ id: "Settings.repeateNewPassword" })}
                    isInvalid={isRepeatePasswordError}
                    error={intl.formatMessage({ id: "Settings.Errors.passwordNotMatch" })}
                >
                    <EuiFieldPassword
                        isInvalid={isRepeatePasswordError}
                        value={newPassword2}
                        onChange={e => setNewPassword2(e.target.value)}
                        onFocus={resetError}
                    />
                </EuiFormRow>

                <EuiSpacer />

                <SaveButton
                    onClick={handleUpdatePassword}
                    loading={loading}
                    disabled={!isValid}
                    error={error}
                >
                    <FormattedMessage id="Settings.save" />
                </SaveButton>
            </EuiForm>
        </Fragment>
    );
}

export default UpdatePassword;
