import React, { useState, Fragment } from 'react';
import {
    EuiFormRow,
    EuiFieldText,
    EuiButtonIcon,
    EuiText,
    EuiTextAlign,
    EuiPanel,
} from '@elastic/eui';
import { FormattedMessage, useIntl } from 'react-intl';

import ImagePage from '../../components/ImagePage';
import { createByEmailAll } from '../../api/services/user'


const Register = () => {
    const intl = useIntl()

    const [username, setUsername] = useState("")
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)

    const handleRegister = () => {
        setLoading(true)
        createByEmailAll(username)
            .then(res => setSuccess(true))
            .catch(e => {
                setError(intl.formatMessage({ id: 'Register.Errors.general' }))
            })
            .then(() => setLoading(false))
    }

    const handleKeyPress = e => {
        if (e.key === "Enter" && username.length > 0 && !loading) {
            handleRegister()
        }
    }

    return (
        <ImagePage
            bottom={
                <EuiText>
                    <EuiTextAlign textAlign="center" >
                        <h2><FormattedMessage id="Register.header" /></h2>
                        <h3><FormattedMessage id="Register.subHeader" /></h3>
                    </EuiTextAlign>
                </EuiText>
            }
        >
            {!success && (
                <EuiFormRow isInvalid={error !== null} label={intl.formatMessage({ id: "Register.usernameLabel" })} error={error} >
                    <EuiFieldText
                        style={{ textAlign: 'center' }}
                        placeholder={intl.formatMessage({ id: "Register.usernamePlaceholder" })}
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        onFocus={() => setError(null)}
                        isInvalid={error !== null}
                        isLoading={loading}
                        disabled={loading}
                        onKeyPress={handleKeyPress}
                        append={!loading ?
                            <EuiButtonIcon
                                iconType="arrowRight"
                                disabled={username.length <= 0 || loading}
                                onClick={handleRegister}
                                aria-label="Register"
                            />
                            :
                            null
                        }
                    />
                </EuiFormRow>
            )}

            {/* Forces text field to be larger */}
            <div style={{ width: 400 }} />

            {success && (
                <Fragment>
                    <EuiPanel>
                        <EuiText>
                            <EuiTextAlign textAlign="center" >
                                <h3><FormattedMessage id="Register.success" /></h3>
                                <p><FormattedMessage id="Register.successMessage" /></p>
                            </EuiTextAlign>
                        </EuiText>
                    </EuiPanel>
                </Fragment>
            )}
        </ImagePage>
    );
}

export default Register;
