import { useState, useEffect } from 'react';

import { getUserAll } from './services/user';

const _getUserAddress = (address = {}) => {
    return {
        address1: address?.address1,
        address2: address?.address2,
        zip: address?.zip,
        city: address?.city,
        phone: address?.phone,
        mobile: address?.mobile,
    }
}

const _getUserData = data => {
    if (!data) return { userData: {}, isDiff: false }

    const userData = data.reduce((acc, { user: userData }) => {
        if (!userData) return acc

        const newData = acc

        const address = userData?.address ? userData.address : {}
        const user = {
            name: userData?.name,
            address: _getUserAddress(address),
        }

        if (!acc.name || (user.name && user.name.length > acc.name.length)) newData.name = user.name
        if (!acc.address.address1 || (user.address.address1 && user.address.address1.length > acc.address.address1.length)) newData.address.address1 = user.address.address1
        if (!acc.address.address2 || (user.address.address2 && user.address.address2.length > acc.address.address2.length)) newData.address.address2 = user.address.address2
        if (!acc.address.zip || (user.address.zip && user.address.zip.length > acc.address.zip.length)) newData.address.zip = user.address.zip
        if (!acc.address.city || (user.address.city && user.address.city.length > acc.address.city.length)) newData.address.city = user.address.city
        if (!acc.address.phone || (user.address.phone && user.address.phone.length > acc.address.phone.length)) newData.address.phone = user.address.phone
        if (!acc.address.mobile || (user.address.mobile && user.address.mobile.length > acc.address.mobile.length)) newData.address.mobile = user.address.mobile

        return newData
    }, { name: null, address: {} })

    const isDiff = data.some(({ user, error }) => (user && !error) && (user.name !== userData.name || JSON.stringify(_getUserAddress(user.address)) !== JSON.stringify(userData.address)))

    return { userData, isDiff }
}

const useUser = (deps = []) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);
    const [isDiff, setIsDiff] = useState(false);

    const setUserData = data => {
        const { userData, isDiff } = _getUserData(data);

        setData(userData)
        setIsDiff(isDiff)
    }

    const handleGetUser = () => {
        setError(null)
        setIsLoading(true)

        getUserAll().then(res => {
            setUserData(res.data)
            setIsLoading(false)
        }).catch(e => {
            setError(e)
            setIsLoading(false)
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(handleGetUser, deps)

    return [isLoading, error, data, isDiff, setUserData]
}

export default useUser