import React, { Fragment, useState } from 'react';
import {
    EuiText,
    EuiTextColor,
    EuiSpacer,
    EuiLink,
    EuiHorizontalRule,
} from '@elastic/eui';
import { FormattedMessage, useIntl } from 'react-intl';

import EuiLinkButton from '../../components/EuiLinkButton'
import useApi from '../../api/useApi'
import { getUserManufacturers } from '../../api/services/user'
import OctaosLoader from '../../components/OctaosLoader';
import UpdateEmailModal from './UpdateEmailModal';

const MyAccounts = ({ user }) => {
    const intl = useIntl()

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [currentManufacturerUser, setCurrentManufacturerUser] = useState(null)
    const [reload, setReload] = useState(null)

    const [loading, error, manufacturers] = useApi(() => getUserManufacturers(user.id), [user, reload], user)

    const handleOpenModal = manufacturerUser => {
        setCurrentManufacturerUser(manufacturerUser)
        setIsModalVisible(true)
    }

    const handleCloseModal = isSuccess => {
        setIsModalVisible(false)
        if (isSuccess) setReload(!reload)
    }

    return (
        <Fragment>
            <EuiText>
                <h2><FormattedMessage id="MyAccounts.header" /></h2>
            </EuiText>

            <EuiSpacer />

            {loading && <OctaosLoader text={intl.formatMessage({ id: "MyAccounts.loaderText" })} textAlign="left" />}

            {!loading && !error && manufacturers && manufacturers.map(({ guid, name, groups, domain, email }, index) => {
                const href = domain ? domain.substring(0, 4) === "http" ? domain : `https://${domain}` : null

                return (
                    <Fragment key={guid} >
                        <EuiText>
                            <h3>{name}</h3>
                            {groups && groups.length > 0 && (
                                <p><FormattedMessage id="MyAccounts.account" /> <FormattedMessage id={`UserGroups.${groups[0].name}`} /></p>
                            )}

                            {email && (
                                <p>
                                    <FormattedMessage id="MyAccounts.email" values={{ email }} />
                                    {email && user.email !== email && (
                                        <EuiTextColor color="warning" >
                                            <br />
                                            <FormattedMessage id="MyAccounts.emailWarningText1" />{' '}
                                            <EuiLink onClick={() => handleOpenModal({ guid, name, email })} >
                                                <FormattedMessage id="MyAccounts.emailWarningTextButton" />
                                            </EuiLink>{' '}
                                            <FormattedMessage id="MyAccounts.emailWarningText2" />
                                        </EuiTextColor>
                                    )}
                                </p>
                            )}

                            {domain && domain.length > 0 && (
                                <p>
                                    <EuiLink external href={href} >
                                        <FormattedMessage id="MyAccounts.goToLoginPage" />
                                    </EuiLink>
                                </p>
                            )}
                        </EuiText>

                        {index < manufacturers.length - 1 && <EuiHorizontalRule />}
                    </Fragment>
                )
            })}

            <EuiSpacer size="xxl" />

            <EuiLinkButton fill to="/dashboard" >
                <FormattedMessage id="MyAccounts.goToProfilePage" />
            </EuiLinkButton>

            <UpdateEmailModal
                user={user}
                manufacturerUser={currentManufacturerUser}
                isModalVisible={isModalVisible}
                closeModal={handleCloseModal}
            />
        </Fragment>
    );
}

export default MyAccounts;
