import React, { Fragment, useState } from 'react';
import { EuiText, EuiTextAlign, EuiButton } from '@elastic/eui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Redirect } from "react-router-dom"

import useApi from '../../api/useApi';
import { activateAccount } from '../../api/services/user';
import EuiLinkButton from '../../components/EuiLinkButton';
import OctaosLoader from '../../components/OctaosLoader';
import { setToken } from '../../helpers/auth'

const AccountActivation = ({ match: { params: { code } } }) => {
    const [retry, setRetry] = useState(false)

    const intl = useIntl()
    const [loading, error, res] = useApi(() => activateAccount(code), [retry])

    const success = !error && res?.token && res?.mid
    const isError = !loading && (error || !res?.success)

    if (success) {
        setToken(res.token, res.mid)
        
        return <Redirect to="/setPassword" />
    }

    return (
        <Fragment>
            <div style={{ height: 100 }} />

            {loading && <OctaosLoader text={intl.formatMessage({ id: "AccountActivation.loaderText" })} />}

            <EuiText>
                <EuiTextAlign textAlign="center" >
                    {isError && <h2><FormattedMessage id="AccountActivation.error" /></h2>}

                    {!loading && (
                        <EuiLinkButton to="/" ><FormattedMessage id="AccountActivation.goBack" /></EuiLinkButton>
                    )}

                    {isError && (
                        <EuiButton fill style={{ marginLeft: 8 }} onClick={() => setRetry(!retry)} >
                            <FormattedMessage id="AccountActivation.retry" />
                        </EuiButton>
                    )}
                </EuiTextAlign>
            </EuiText>
        </Fragment>
    );
}

export default AccountActivation;
