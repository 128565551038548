import React from 'react';
import ReactDOM from 'react-dom';
import { EuiGlobalToastList } from '@elastic/eui';

const toastTime = 10000;
const portalRoot = document.getElementById("portal");

const Error = ({ msg }) => {
    return (
        <EuiGlobalToastList
            toastLifeTimeMs={toastTime}
            toasts={[
                {
                    title: "Something went wrong",
                    color: "danger",
                    iconType: "alert",
                    text: <p>{msg}</p>,
                }
            ]}
        />
    )
}

export const showError = (msg) => {
    ReactDOM.render(<Error msg={msg} />, portalRoot);
    setTimeout(() => ReactDOM.unmountComponentAtNode(portalRoot), toastTime + 100)
}

