import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { Redirect } from "react-router-dom"
import { FormattedMessage, useIntl } from 'react-intl';

import {
    EuiFieldText,
    EuiButtonIcon,
    EuiFormRow,
    EuiText,
    EuiTextAlign,
    EuiSpacer,
} from '@elastic/eui';

import { login } from '../api/services/auth'
import { setToken, isAuthenticated } from '../helpers/auth'
import { setGoBackTo } from '../helpers/storage'
import EuiLinkButton from '../components/EuiLinkButton'
import ImagePage from '../components/ImagePage';
import { isDarkTheme } from '../helpers/utils';
import { options } from '../components/LanguagePicker'
import TwoFactorModal from '../components/TwoFactorModal';

const Login = ({ location: { search }, onLanguageChange }) => {
    const history = useHistory();
    const intl = useIntl();

    const [showPassword, setShowPassword] = useState(true)
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [redirect, setRedirect] = useState(false)
    const [twoFactorModalOpen, setTwoFactorModalOpen] = useState(false);
    const [twoFactorModalUsername, setTwoFactorModalUsername] = useState();
    const [twoFactorModalToken, setTwoFactorModalToken] = useState();
    const [mid, setMid] = useState();

    const queryString = require('query-string');
    const query = queryString.parse(search);

    if (query?.lang) {
        const lang = options.find(l => l.value === query.lang)

        if (lang) onLanguageChange(lang.value)
    }

    if (query?.goBackTo) {
        setGoBackTo(query.goBackTo)

        if (!query?.token) history.replace("/")
    }

    if (query?.token && query?.mid) {
        setToken(query.token, query.mid)

        history.replace("/")
    }

    const handleToken = (token, xMid) => {
        setToken(token, xMid || mid);

        setRedirect(true);
    }

    const handleLogin = e => {
        if (e) {
            e.preventDefault()
        }

        setLoading(true);
        setTwoFactorModalUsername(username);

        login({ username, password })
            .then(res => {
                if (res.data.type === "two-factor-app") {
                    setMid(res.data.mid);
                    setTwoFactorModalToken(res.data.token);
                    setTwoFactorModalOpen(true);
                } else {
                    handleToken(res.data.token, res.data.mid);
                }
            })
            .catch(e => {
                setLoading(false)

                const key = e.status === 401 ? "wrongUsernameOrPassword" : "general"
                const msg = intl.formatMessage({ id: `Login.Errors.${key}` })

                setError(msg)
            })
    }

    const handleTwoFactorModalClose = () => {
        setTwoFactorModalOpen(false);
        setLoading(false);
    }

    const handleKeyPress = e => {
        if (e.key === "Enter" && password.length > 0 && username.length > 0 && !loading) {
            handleLogin()
        }
    }

    if (redirect || isAuthenticated()) {
        let to = "/";

        if (typeof query?.redirectTo === "string") to = query.redirectTo;

        return <Redirect to={to} />;
    }

    return (
        <ImagePage
            bottom={
                <EuiText>
                    <EuiTextAlign textAlign="center" >
                        <h2><FormattedMessage id="Login.header" /></h2>
                        <h3><FormattedMessage id="Login.subHeader" /></h3>
                    </EuiTextAlign>
                </EuiText>
            }
        >
            <form onSubmit={handleLogin} >
                <EuiFormRow isInvalid={error !== null} >
                    <EuiFieldText
                        id="email"
                        name="email"
                        style={{ textAlign: 'center' }}
                        placeholder={intl.formatMessage({ id: "Login.usernamePlaceholder" })}
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        onFocus={() => setError(null)}
                        append={!showPassword ?
                            <EuiButtonIcon
                                iconType="arrowRight"
                                disabled={username.length <= 0}
                                onClick={() => setShowPassword(true)}
                                aria-label="Next"
                            />
                            : null
                        }
                        isInvalid={error !== null}
                        onKeyPress={handleKeyPress}
                    />
                </EuiFormRow>
                <EuiFormRow isInvalid={error !== null} error={error} >
                    <EuiFieldText
                        id="password"
                        name="password"
                        style={{ textAlign: 'center' }}
                        type="password"
                        placeholder={intl.formatMessage({ id: "Login.passwordPlaceholder" })}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        onFocus={() => setError(null)}
                        append={!loading ?
                            <EuiButtonIcon
                                type="submit"
                                iconType="arrowRight"
                                disabled={password.length <= 0 || loading}
                                aria-label="Login"
                            />
                            :
                            null
                        }
                        isInvalid={error !== null}
                        isLoading={loading}
                        onKeyPress={handleKeyPress}
                    />
                </EuiFormRow>
            </form>

            <EuiSpacer size="xxl" />

            <div style={{ backgroundColor: isDarkTheme ? 'rgba(0, 0, 0, 0.75)' : 'rgba(255, 255, 255, 0.75)', textAlign: 'center' }} >
                <EuiLinkButton to="resetPassword" empty color="text" >
                    <FormattedMessage id="Login.forgottenPassword" />
                </EuiLinkButton>
            </div>

            {twoFactorModalOpen && (
                <TwoFactorModal
                    onToken={handleToken}
                    twoFactorToken={twoFactorModalToken}
                    username={twoFactorModalUsername}
                    closeModal={handleTwoFactorModalClose}
                />
            )}
        </ImagePage>
    )
}

export default Login;
