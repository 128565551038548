import React, { useState } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiFieldPassword,
    EuiSpacer,
} from '@elastic/eui';
import { FormattedMessage, useIntl } from 'react-intl';

import SaveButton from './SaveButton';

const PasswordForm = ({ loading, error, resetError, onClick, isSetPassword = false }) => {
    const intl = useIntl()

    const [newPassword, setNewPassword] = useState("")
    const [newPassword2, setNewPassword2] = useState("")

    const isRepeatePasswordError = newPassword.length >= 8 && newPassword2.length >= 8 && newPassword !== newPassword2
    const isValid = newPassword.length >= 8 && newPassword2.length >= 8 && newPassword === newPassword2

    return (
        <EuiForm>
            <EuiFormRow
                label={intl.formatMessage({ id: isSetPassword ? "PasswordForm.password" : "PasswordForm.newPassword" })}
                helpText={intl.formatMessage({ id: "PasswordForm.newPasswordHelpText" })}
            >
                <EuiFieldPassword
                    value={newPassword}
                    onChange={e => setNewPassword(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiFormRow
                label={intl.formatMessage({ id: isSetPassword ? "PasswordForm.repeatePassword" : "PasswordForm.repeateNewPassword" })}
                isInvalid={isRepeatePasswordError}
                error={intl.formatMessage({ id: "PasswordForm.Errors.passwordNotMatch" })}
            >
                <EuiFieldPassword
                    isInvalid={isRepeatePasswordError}
                    value={newPassword2}
                    onChange={e => setNewPassword2(e.target.value)}
                    onFocus={resetError}
                />
            </EuiFormRow>

            <EuiSpacer />

            <SaveButton
                onClick={() => onClick(newPassword)}
                loading={loading}
                disabled={!isValid}
                error={error}
            >
                <FormattedMessage id="PasswordForm.save" />
            </SaveButton>
        </EuiForm>
    );
}

export default PasswordForm;
