import React from 'react';
import { EuiText } from '@elastic/eui';

const OctaosLoader = ({ text, textAlign = 'center' }) => {
    return (
        <div style={{ textAlign }} >
            <object data="./images/OctaosArchInfinite.svg" title="Octaos Loader" style={{ height: 120 }} />
            {text && <EuiText><h2>{text}</h2></EuiText>}
        </div>
    )
}

export default OctaosLoader;
