import React, { Fragment } from 'react';
import { Route } from "react-router-dom"

import MainNavigator from '../MainNaviagator';

import { isAuthenticated } from '../../helpers/auth'

const HybridRoute = ({ path, component, shrink = true, currentLanguageCode, onLanguageChange, ...props }) => {
    const isAuth = isAuthenticated()

    return (
        <Fragment>
            <MainNavigator isAuth={isAuth} currentLanguageCode={currentLanguageCode} onLanguageChange={onLanguageChange} />

            <div
                style={{
                    marginTop: 81,
                    marginLeft: shrink ? 32 : 0,
                    marginRight: shrink ? 32 : 0,
                    marginBottom: 32,
                }}
            >
                <Route path={path} component={({ ...props }) => component({ ...props, isAuth })} {...props} />
            </div>
        </Fragment>
    );
}

export default HybridRoute;