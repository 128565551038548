import { Fragment, useState } from "react";
import { Redirect } from "react-router-dom"
import {
    EuiText,
    EuiButton,
    EuiSpacer,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import DeleteAccountModal from "./DeleteAccountModal";

import { getToken } from '../../helpers/auth';

const DeleteAccount = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const handleClick = () => {
        setIsModalVisible(true);
    }

    const handleCloseModal = () => setIsModalVisible(false);

    const handleDeleted = () => {
        localStorage.setItem("deletedToken", getToken());

        setRedirect(true);
    }

    if (redirect) return <Redirect to="/accountDeleted" />

    return (
        <Fragment>
            <EuiText>
                <h2><FormattedMessage id="DeleteAccount.header" /></h2>
            </EuiText>

            <EuiSpacer />

            <EuiText>
                <FormattedMessage id="DeleteAccount.info" />
            </EuiText>

            <EuiSpacer />

            <EuiButton onClick={handleClick} fill color="danger" >
                <FormattedMessage id="DeleteAccount.btnText" />
            </EuiButton>

            <DeleteAccountModal
                isModalVisible={isModalVisible}
                closeModal={handleCloseModal}
                onDeleted={handleDeleted}
            />
        </Fragment>
    );
};

export default DeleteAccount;
