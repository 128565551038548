import React, { Fragment } from 'react';
import {
    EuiText,
} from '@elastic/eui';


const PrivacyPolicy = ({ isAuth }) => {
    return (
        <Fragment>
            <EuiText grow={false} >
                <h2>Privacy policy</h2>

            </EuiText>

        </Fragment>
    );
}

export default PrivacyPolicy;
