import React, { useState } from 'react';
import {
    EuiForm,
    EuiFormRow,
    EuiSwitch,
    EuiHeaderSectionItemButton,
    EuiPopover,
    EuiPopoverTitle,
    EuiIcon,
    EuiSpacer,
} from '@elastic/eui';
import { htmlIdGenerator } from '@elastic/eui/lib/services';
import { useIntl, FormattedMessage } from 'react-intl';
import LanguagePicker from '../LanguagePicker';

const HeaderSettings = ({ currentLanguageCode, onLanguageChange }) => {
    const id = htmlIdGenerator()();
    const intl = useIntl();

    const [isOpen, setIsOpen] = useState(false);
    const [isDark, setIsDark] = useState(localStorage.getItem("theme") === "dark")

    const handleDarkModeChange = e => {
        const _isDark = e.target.checked

        setIsDark(_isDark)
        localStorage.setItem("theme", _isDark ? "dark" : "light")

        window.location.reload()
    }

    const onMenuButtonClick = () => setIsOpen(!isOpen);
    const closeMenu = () => setIsOpen(false);

    const button = (
        <EuiHeaderSectionItemButton
            aria-controls={id}
            aria-expanded={isOpen}
            aria-haspopup="true"
            aria-label="Settings"
            onClick={onMenuButtonClick}
        >
            <EuiIcon type="gear" />
        </EuiHeaderSectionItemButton>
    );

    return (
        <EuiPopover
            id={id}
            button={button}
            isOpen={isOpen}
            anchorPosition="downRight"
            closePopover={closeMenu}
        >
            <EuiPopoverTitle><FormattedMessage id="Settings.HeaderSettingsTitle" /></EuiPopoverTitle>

            <div style={{ width: 240 }}>
                <EuiForm>
                    <EuiFormRow display="columnCompressedSwitch" >
                        <EuiSwitch label={intl.formatMessage({ id: "Settings.darkMode" })} checked={isDark} onChange={handleDarkModeChange} />
                    </EuiFormRow>
                </EuiForm>

                <EuiSpacer />

                <LanguagePicker currentCode={currentLanguageCode} onChange={onLanguageChange} />
            </div>
        </EuiPopover>
    );
}

export default HeaderSettings;
