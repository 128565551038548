import React, { Fragment, useState } from 'react';
import {
    EuiButton,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiLink,
    EuiText,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import useApi from '../../api/useApi'
import { addUserEmailResend } from '../../api/services/user'

const ResendModal = ({ user, email }) => {
    const [isModalVisible, setIsModalVisible] = useState(false)

    const openModal = () => setIsModalVisible(true)

    const Modal = () => {
        const [loading, error, res] = useApi(() => addUserEmailResend(user.id, email))

        const closeModal = () => {
            if (!loading)
                setIsModalVisible(false)
        }

        const isSuccess = !error && res?.success

        return (
            <EuiOverlayMask>
                <EuiModal onClose={closeModal} >
                    <EuiModalHeader>
                        <EuiModalHeaderTitle><FormattedMessage id="Dashboard.ResendModal.title" /></EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>
                        <EuiText>
                            <p>
                                {loading && <FormattedMessage id="Dashboard.ResendModal.sending" />}
                                {!loading && isSuccess && <FormattedMessage id="Dashboard.ResendModal.sent" />}
                                {!loading && !isSuccess && <FormattedMessage id="Dashboard.ResendModal.error" />}
                            </p>
                        </EuiText>
                    </EuiModalBody>

                    <EuiModalFooter>
                        <EuiButton onClick={closeModal} fill isDisabled={loading} isLoading={loading} >
                            <FormattedMessage id="Dashboard.ResendModal.close" />
                        </EuiButton>
                    </EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask>
        )
    }

    return (
        <Fragment>
            <EuiLink onClick={openModal} >
                <FormattedMessage id="Dashboard.ResendModal.buttonText" />
            </EuiLink>

            {isModalVisible && <Modal />}
        </Fragment>
    );
}

export default ResendModal;
