import React, { Fragment } from 'react';
import { Route, Redirect } from "react-router-dom"
import { useIntl } from 'react-intl';

import MainNavigator from '../MainNaviagator';
import Status from '../../views/Status'

import { isAuthenticated } from '../../helpers/auth'
import useUser from '../../api/useUser';
import OctaosLoader from '../OctaosLoader';

const AuthRoute = ({ path, component, currentLanguageCode, onLanguageChange, ...props }) => {
    const intl = useIntl()

    const isAuth = isAuthenticated()
    const [loading, error, user] = useUser()

    if (!isAuth) return <Redirect to="/login" />

    if (error) return <Status />

    return (
        <Fragment>
            <MainNavigator isAuth currentLanguageCode={currentLanguageCode} onLanguageChange={onLanguageChange} />

            {/* <div style={{ height: 32 }} /> */}

            <div
                style={{
                    marginTop: 81,
                    marginLeft: 32,
                    marginRight: 32,
                    marginBottom: 32,
                }}
            >
                {loading ?
                    <OctaosLoader text={intl.formatMessage({ id: "AuthRoute.loaderText" })} />
                    :
                    <Route path={path} component={({ ...props }) => component({ ...props, user })} {...props} />
                }
            </div>

        </Fragment>
    );
}

export default AuthRoute;