import React, { useState } from 'react';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

const ConfirmDialog = ({ title, body, closeModal, onConfirm }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const handleConfirm = async () => {
        setLoading(true)
        setError(null)

        try {
            await onConfirm()

            setLoading(false)
            closeModal()
        } catch (error) {
            setLoading(false)
            setError(error)
        }


    }

    return (
        <EuiOverlayMask>
            <EuiModal onClose={closeModal} >
                <EuiModalHeader>
                    <EuiModalHeaderTitle>{title}</EuiModalHeaderTitle>
                </EuiModalHeader>

                <EuiModalBody>
                    <EuiText>
                        <p>{body}</p>
                        {error && (
                            <p><EuiTextColor color="danger" ><FormattedMessage id="ConfirmDialog.Errors.general" /></EuiTextColor></p>
                        )}
                    </EuiText>
                </EuiModalBody>

                <EuiModalFooter>
                    <EuiButtonEmpty onClick={closeModal}>
                        <FormattedMessage id="ConfirmDialog.cancel" />
                    </EuiButtonEmpty>

                    <EuiButton onClick={handleConfirm} isLoading={loading} fill color="danger" >
                        <FormattedMessage id="ConfirmDialog.confirm" />
                    </EuiButton>
                </EuiModalFooter>
            </EuiModal>
        </EuiOverlayMask>
    )
}

export default ConfirmDialog;
