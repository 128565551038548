import React, { Fragment } from 'react';
import {
    EuiText,
} from '@elastic/eui';


const TermsOfService = ({ isAuth }) => {
    return (
        <Fragment>
            <EuiText grow={false} >
                <h2>Terms of service</h2>

            </EuiText>

        </Fragment>
    );
}

export default TermsOfService;
