import React from 'react';
import {
    EuiText,
    EuiTextAlign,
} from '@elastic/eui';

import EuiLinkButton from '../../components/EuiLinkButton';
import { isAuthenticated } from '../../helpers/auth'
import { FormattedMessage } from 'react-intl';

const Status = ({ status }) => {
    return (
        <EuiText>
            <EuiTextAlign textAlign="center" >
                <h2>
                    <FormattedMessage
                        id={`Status.${status}`}
                        defaultMessage={<FormattedMessage id="Status.general" />}
                    />
                </h2>

                {status && <p><FormattedMessage id="Status.status" values={{ status }} /></p>}

                <EuiLinkButton to="/" ><FormattedMessage id="Status.goHome" /></EuiLinkButton>

                {isAuthenticated() && (
                    <EuiLinkButton style={{ marginLeft: 16 }} to="/logout" shouldReload={status !== 404} >
                        <FormattedMessage id="Status.signOut" />
                    </EuiLinkButton>
                )}
            </EuiTextAlign>
        </EuiText>
    );
}

export default Status;
