import React from 'react';
import { useHistory } from 'react-router';
import {
    EuiControlBar,
    EuiLink,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

const Footer = () => {
    const history = useHistory()

    const isModifiedEvent = (event) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

    const isLeftClickEvent = (event) => event.button === 0

    const onClick = (event, to) => {
        if (event.defaultPrevented) {
            return
        }

        if (event.target.getAttribute('target')) {
            return
        }

        if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
            return
        }

        event.preventDefault()

        history.push(to)
    }

    const codeControls = [
        // {
        //     controlType: 'divider',
        // },
        {
            controlType: 'text',
            id: 'terms_of_service',
            text: <EuiLink onClick={(e) => onClick(e, "/termsOfService")} ><FormattedMessage id="Footer.termsOfService" /></EuiLink>,
        },
        {
            controlType: 'spacer',
        },
        {
            controlType: 'text',
            id: 'privacy_policy',
            text: <EuiLink onClick={(e) => onClick(e, "/privacyPolicy")} ><FormattedMessage id="Footer.privacyPolicy" /></EuiLink>,
        },
        {
            controlType: 'spacer',
        },
        {
            controlType: 'text',
            id: 'copyright_text',
            text: `Copyright © ${new Date().getFullYear()} Octaos AS - All rights reserved`,
            className: 'eui-hideFor--s eui-hideFor--xs',
        },
        {
            controlType: 'spacer',
        },
        {
            controlType: 'text',
            id: 'use_of_cookies',
            text: <EuiLink onClick={(e) => onClick(e, "/useOfCookies")} ><FormattedMessage id="Footer.useOfCookies" /></EuiLink>,
        },
        {
            controlType: 'spacer',
        },
        {
            controlType: 'text',
            id: 'about',
            text: <EuiLink onClick={(e) => onClick(e, "/about")} ><FormattedMessage id="Footer.about" /></EuiLink>,
        },
    ];


    return (
        <footer>
            <EuiControlBar showOnMobile controls={codeControls} />
        </footer>
    )
}

export default Footer;
