import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

import { isAuthenticated } from './helpers/auth'
import MyIntlProvider from './MyIntlProvider';
import { getCurrentLanguage, setCurrentLanguage, isGoBackTo, deleteGoBackTo } from './helpers/storage';

import AnonRoute from './components/Routes/AnonRoute';
import HybridRoute from './components/Routes/HybridRoute';
import AuthRoute from './components/Routes/AuthRoute';

import Footer from './components/Footer';
import MyCookieConsent from './components/MyCookieConsent';

import Login from './views/Login';
import Status from './views/Status';
import Dashboard from './views/Dashboard';
import Logout from './views/Logout';
import FAQ from './views/FAQ';
import About from './views/About';
import TermsOfService from './views/TermsOfService';
import PrivacyPolicy from './views/PrivacyPolicy';
import UseOfCookies from './views/UseOfCookies';
import Register from './views/Register';
import VerifyEmail from './views/VerifyEmail';
import Settings from './views/Settings';
import MyAccounts from './views/MyAccounts';
import ResetPassword from './views/ResetPassword';
import ResetPasswordConfirm from './views/ResetPasswordConfirm';
import AccountActivation from './views/AccountActivation';
import SetPassword from './views/AccountActivation/SetPassword';
import DeleteAccount from './views/DeleteAccount';
import AccountDeleted from './views/AccountDeleted';

function App() {
	const [currentLanguageCode, setCurrentLanguageCode] = useState(getCurrentLanguage())

	const handleLanguageCodeChange = code => {
		setCurrentLanguageCode(code)
		setCurrentLanguage(code)
	}

	const languageProps = { onLanguageChange: handleLanguageCodeChange, currentLanguageCode: currentLanguageCode }

	if (!isAuthenticated() && isGoBackTo()) {
		deleteGoBackTo()
	}

	return (
		<Router>
			<MyIntlProvider code={currentLanguageCode} >

				<Switch>
					<Route path="/" exact component={() => <Redirect to={isAuthenticated() ? "/dashboard" : "/login"} />} />

					<AnonRoute shrink={false} path="/login" exact component={({ ...props }) => <Login {...props} {...languageProps} />} {...languageProps} />
					<AnonRoute shrink={false} path="/register" component={Register} {...languageProps} />
					<AnonRoute shrink={false} path="/resetPassword" component={ResetPassword} {...languageProps} />

					<HybridRoute path="/faq" exact component={FAQ} {...languageProps} />
					<HybridRoute path="/about" exact component={About} {...languageProps} />
					<HybridRoute path="/termsOfService" exact component={TermsOfService} {...languageProps} />
					<HybridRoute path="/privacyPolicy" exact component={PrivacyPolicy} {...languageProps} />
					<HybridRoute shrink={false} path="/useOfCookies" exact component={UseOfCookies} {...languageProps} />

					<AuthRoute path="/dashboard" component={Dashboard} {...languageProps} />
					<AuthRoute path="/myAccounts" component={MyAccounts} {...languageProps} />
					<AuthRoute path="/deleteAccount" component={DeleteAccount} {...languageProps} />
					<AuthRoute path="/settings" component={Settings} {...languageProps} />
					<AuthRoute path="/logout" component={Logout} {...languageProps} />

					<Route path="/add-email/:code" exact component={VerifyEmail} />
					<Route path="/reset-password/:code" exact component={ResetPasswordConfirm} />
					<Route path="/account-activation/:code" exact component={AccountActivation} />

					<Route path="/setPassword" exact component={SetPassword} />

					<Route path="/accountDeleted" exact component={AccountDeleted} />

					<Route component={() => <Status status="404" />} />
				</Switch>

				<Footer />

				<MyCookieConsent />
			</MyIntlProvider>
		</Router>
	);
}

export default App;
