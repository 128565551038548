import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import {
    EuiButton,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiFormRow,
    EuiFieldText,
    EuiSpacer,
} from '@elastic/eui';
import { FormattedMessage, useIntl } from 'react-intl';

import { getApiUrl } from '../helpers/utils';
import { twoFactorConfirm } from '../api/services/auth';

const TwoFactorModal = ({ onToken, twoFactorToken, username, closeModal }) => {
    const intl = useIntl();

    const [code, setCode] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        const socket = io(getApiUrl(), { query: { twoFactorToken, username } });

        socket.on("auth:login:res", event => {
            socket.disconnect();

            onToken(event.token);
        });

        return () => {
            socket.disconnect();
        }
    }, [twoFactorToken, username, onToken])

    const handleLogin = async e => {
        if (e) {
            e.preventDefault()
        }

        setLoading(true)

        try {
            const res = await twoFactorConfirm(username, code, twoFactorToken);

            setLoading(false);

            if (res?.data?.token) {
                onToken(res.data.token);
            } else {
                throw new Error("general");
            }
        } catch (error) {
            setLoading(false);

            const key = error?.data?.msgKey || "general";

            const msg = intl.formatMessage({
                id: `TwoFactorModal.Errors.${key}`,
                defaultMessage: intl.formatMessage({ id: "TwoFactorModal.Errors.general" }),
            });

            setError(msg);
        }
    };

    const handleKeyPress = e => {
        if (e.key === "Enter" && code.length > 0 && !loading) {
            handleLogin();
        }
    }

    return (
        <EuiModal onClose={closeModal}>
            <EuiModalHeader>
                <EuiModalHeaderTitle>
                    <h1><FormattedMessage id="TwoFactorModal.title" /></h1>
                </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
                <FormattedMessage id="TwoFactorModal.body" />

                <EuiSpacer />

                <form onSubmit={handleLogin} >
                    <EuiFormRow isInvalid={error !== null} error={error} helpText={intl.formatMessage({ id: "TwoFactorModal.codeHelpText" })} >
                        <EuiFieldText
                            id="code"
                            name="code"
                            placeholder={intl.formatMessage({ id: "TwoFactorModal.codePlaceholder" })}
                            value={code}
                            onChange={e => setCode(e.target.value)}
                            onFocus={() => setError(null)}
                            isInvalid={error !== null}
                            onKeyPress={handleKeyPress}
                            append={
                                <EuiButton
                                    type="submit"
                                    fill
                                    disabled={code.length <= 0 || loading}
                                    isLoading={loading}
                                >
                                    <FormattedMessage id="TwoFactorModal.codeSend" />
                                </EuiButton>
                            }
                        />
                    </EuiFormRow>


                </form>
            </EuiModalBody>

            <EuiModalFooter>
                <EuiButton onClick={closeModal} fill>
                    <FormattedMessage id="TwoFactorModal.close" />
                </EuiButton>
            </EuiModalFooter>
        </EuiModal>
    )
}

export default TwoFactorModal;
