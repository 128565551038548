import { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { EuiText } from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import { deleteToken, getToken } from '../../helpers/auth';

const AccountDeleted = () => {
    const [redirect, setRedirect] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);

    useEffect(() => {
        const deletedToken = localStorage.getItem("deletedToken");
        const token = getToken();

        if (deletedToken && token && deletedToken === token) {
            deleteToken();

            localStorage.removeItem("user");
            localStorage.removeItem("deletedToken");

            setShouldRender(true);
        } else {
            setRedirect(true);
        }
    }, [])

    if (redirect) return <Redirect to="/" />;

    if (!shouldRender) return null;

    return (
        <div style={{ marginTop: 32 }} >
            <EuiText textAlign="center" >
                <FormattedMessage id="AccountDeleted.message" />
            </EuiText>
        </div>
    )
}

export default AccountDeleted;
