import React, { useState } from 'react';
import {
    EuiConfirmModal,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
} from '@elastic/eui';
import { useIntl } from 'react-intl';
import { deleteAccount } from '../../api/services/user';

const DeleteAccountModal = ({ isModalVisible, closeModal, onDeleted }) => {
    const intl = useIntl();

    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleCancel = () => {
        setError(null);
        setPassword("");
        closeModal();
    }

    const handleConfirm = async () => {
        setLoading(true);
        setError(null);

        try {
            const res = await deleteAccount(password);

            setLoading(false);

            if (res.data.success) {
                closeModal();

                if (onDeleted) onDeleted();
            } else {
                setError(intl.formatMessage({ id: "DeleteAccount.DeleteAccountModal.Errors.general" }));
            }

        } catch (error) {
            setLoading(false);

            let errorKey = "general";

            switch (error?.data?.msgKey) {
                case "invalid_password":
                    errorKey = "wrongPassword";
                    break;
                default:
                    errorKey = "general";
                    break;
            }

            setError(intl.formatMessage({ id: `DeleteAccount.DeleteAccountModal.Errors.${errorKey}` }))
        }
    }

    if (isModalVisible) {
        return (
            <EuiConfirmModal
                title={intl.formatMessage({ id: "DeleteAccount.DeleteAccountModal.title" })}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                cancelButtonText={intl.formatMessage({ id: "DeleteAccount.DeleteAccountModal.cancelButtonText" })}
                confirmButtonText={intl.formatMessage({ id: "DeleteAccount.DeleteAccountModal.confirmButtonText" })}
                buttonColor="danger"
                isLoading={loading}
                confirmButtonDisabled={loading || password.length <= 0}
            >
                <EuiForm>
                    <EuiFormRow
                        fullWidth
                        label={intl.formatMessage({ id: "DeleteAccount.DeleteAccountModal.password" })}
                        helpText={intl.formatMessage({ id: "DeleteAccount.DeleteAccountModal.info" })}
                        isInvalid={error !== null}
                        error={error ? [error] : null}
                    >
                        <EuiFieldText
                            fullWidth
                            name="password"
                            type="password"
                            value={password}
                            onFocus={_ => setError(null)}
                            onChange={e => setPassword(e.target.value)}
                            isInvalid={error !== null}
                        // onKeyPress={handleKeyPress}
                        />
                    </EuiFormRow>
                </EuiForm>
            </EuiConfirmModal>
        )
    }

    return null;
};

export default DeleteAccountModal;
