import React, { Fragment } from 'react';
import {
    EuiText,
    EuiLink,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import EuiLinkButton from '../../components/EuiLinkButton';

const About = ({ isAuth }) => {
    return (
        <Fragment>
            <EuiText grow={false} >
                <h2><FormattedMessage id="About.header" /></h2>

                <p><FormattedMessage id="About.firstParagraph" /></p>

                <p>
                    <FormattedMessage id="About.secondParagraph" />{' '}
                    <EuiLink href="https://www.octaos.com" target="_blank">
                        www.octaos.com
                    </EuiLink>
                </p>

            </EuiText>
            {!isAuth && (
                <div style={{ marginTop: 32 }} >
                    <EuiLinkButton to="/" ><FormattedMessage id="About.goToLogin" /></EuiLinkButton>
                </div>
            )}
        </Fragment>
    );
}

export default About;
