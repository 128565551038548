import React, { Fragment } from 'react';
import {
    EuiText,
    // EuiTextAlign,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import EuiLinkButton from '../../components/EuiLinkButton';

const FAQ = ({ isAuth }) => {
    return (
        <Fragment>
            <EuiText grow={false} >
                <h2><FormattedMessage id="FAQ.header" /></h2>
                <h3><FormattedMessage id="FAQ.header1" /></h3>
                <p><FormattedMessage id="FAQ.paragraph1" /></p>

                <h3><FormattedMessage id="FAQ.header2" /></h3>
                <p><FormattedMessage id="FAQ.paragraph2" /></p>

                <h3><FormattedMessage id="FAQ.header3" /></h3>
                <p><FormattedMessage id="FAQ.paragraph3" /></p>

                <h3><FormattedMessage id="FAQ.header4" /></h3>
                <p><FormattedMessage id="FAQ.paragraph4" /></p>

                <h3><FormattedMessage id="FAQ.header5" /></h3>
                <p><FormattedMessage id="FAQ.paragraph5" /></p>
            </EuiText>
            {!isAuth && (
                <div style={{ marginTop: 32 }} >
                    <EuiLinkButton to="/" ><FormattedMessage id="FAQ.goToLogin" /></EuiLinkButton>
                </div>
            )}
        </Fragment>
    );
}

export default FAQ;
