import React from 'react';
import {
    EuiSuperSelect,
} from '@elastic/eui';

export const options = [
    {
        value: 'nb',
        inputDisplay: 'Language',
        dropdownDisplay: "Norsk",
    },
    {
        value: 'en',
        inputDisplay: 'Language',
        dropdownDisplay: "English",
    },
]

const LanguagePicker = ({ currentCode, onChange }) => {
    const handleChangeLanguage = code => {
        onChange(code)
    }

    return (
        <EuiSuperSelect
            options={options}
            valueOfSelected={currentCode}
            onChange={handleChangeLanguage}
        />
    );
}

export default LanguagePicker;
