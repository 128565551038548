import React, { Fragment } from 'react';
import { EuiText, EuiSpacer } from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import UpdatePassword from './UpdatePassword';

const Settings = () => {
    return (
        <Fragment>
            <EuiText><h2><FormattedMessage id="Settings.changePassword" /></h2></EuiText>

            <EuiSpacer />

            <UpdatePassword />
        </Fragment>
    );
}

export default Settings;
