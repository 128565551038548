import { useState, useEffect } from 'react';

import { getUser } from './services/user';
import { getToken, getMid } from '../helpers/auth'
import { analytics, setUserId, setUserProperties } from '../helpers/firebase'

const userKey = "user";
const userTokenKey = "userToken";

const useUser = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    const handleGetUser = () => {
        setError(null)
        setIsLoading(true)

        const savedUser = localStorage.getItem(userKey);
        const token = getToken()
        const savedUserToken = localStorage.getItem(userTokenKey)

        const setAnalytics = user => {
            if (analytics) {
                setUserId(analytics, `${user.id}`)
                setUserProperties(analytics, { id: user.id, name: user.name, mid: getMid() });
            }
        }

        if (savedUser && savedUserToken === token) {
            setData(JSON.parse(savedUser))
            setIsLoading(false)

            getUser().then(res => {
                const isDifference = JSON.stringify(res.data) !== JSON.stringify(JSON.parse(savedUser))

                if (isDifference) {
                    setData(res.data)

                    localStorage.setItem(userKey, JSON.stringify(res.data))

                    setAnalytics(res.data);
                }
            })
        } else {
            getUser().then(res => {
                setData(res.data)

                localStorage.setItem(userKey, JSON.stringify(res.data))
                localStorage.setItem(userTokenKey, token)

                setIsLoading(false)

                setAnalytics(res.data);
            }).catch(e => {
                setError(e)
                setIsLoading(false)
            })
        }
    }

    useEffect(handleGetUser, [])

    return [isLoading, error, data]
}

export default useUser