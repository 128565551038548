import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiLink,
    EuiText,
    EuiTextColor,
} from '@elastic/eui';
import { FormattedMessage } from 'react-intl';

import { setUserEmail } from '../../api/services/user'

const SetEmailModal = ({ user, email }) => {
    const history = useHistory();

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [result, setResult] = useState(false)

    const apiSuccess = result?.api?.success

    const openModal = () => setIsModalVisible(true)
    const closeModal = () => {
        setIsModalVisible(false)

        if (apiSuccess) {
            history.push("/logout")
        }
    }

    const handleSet = async () => {
        setLoading(true)

        const res = await setUserEmail(user.id, email)

        setResult(res.data)
        setLoading(false)
    }

    const hasErrors = result && (!result.api.success || result.manufacturers.some(m => !m.success))

    const SuccessMsg = ({ success }) => (
        <EuiTextColor color={success ? "secondary" : "danger"} >
            <FormattedMessage id={`Dashboard.SetEmailModal.${success ? "yes" : "no"}`} />
        </EuiTextColor>
    )

    return (
        <Fragment>
            <EuiLink onClick={openModal} >
                <FormattedMessage id="Dashboard.SetEmailModal.buttonText" />
            </EuiLink>

            {isModalVisible && (
                <EuiOverlayMask>
                    <EuiModal onClose={closeModal} >
                        <EuiModalHeader>
                            <EuiModalHeaderTitle><FormattedMessage id="Dashboard.SetEmailModal.title" /></EuiModalHeaderTitle>
                        </EuiModalHeader>

                        <EuiModalBody>
                            {result ?
                                (
                                    <EuiText>
                                        <h2>
                                            <EuiTextColor color={hasErrors ? "danger" : "secondary"} >
                                                <FormattedMessage id={`Dashboard.SetEmailModal.${hasErrors ? "hasErrors" : "notHasErrors"}`} />
                                            </EuiTextColor>
                                        </h2>
                                        <h3><FormattedMessage id="Dashboard.SetEmailModal.apiUserHeader" /></h3>
                                        <p><FormattedMessage id="Dashboard.SetEmailModal.success" /> <SuccessMsg success={result.api.success} /></p>
                                        {!result.api.success && <p><FormattedMessage id="Dashboard.SetEmailModal.error" /> {result.api.error.msg}</p>}
                                        {result.manufacturer && result.manufacturer.length > 0 && (
                                            <h3><FormattedMessage id="Dashboard.SetEmailModal.manufacturers" /></h3>
                                        )}
                                        {result.manufacturers.map(manufacturer => (
                                            <Fragment key={manufacturer.manufacturer} >
                                                <h4>{manufacturer.manufacturer}</h4>
                                                <p><FormattedMessage id="Dashboard.SetEmailModal.success" /> <SuccessMsg success={manufacturer.success} /></p>
                                                {!manufacturer.success && <p><FormattedMessage id="Dashboard.SetEmailModal.error" /> {manufacturer.error.msg || manufacturer.error.status}</p>}
                                            </Fragment>
                                        ))}
                                    </EuiText>
                                )
                                :
                                (
                                    <EuiText>
                                        <p>
                                            <FormattedMessage id="Dashboard.SetEmailModal.warningMessage" values={{ email }} />
                                        </p>
                                    </EuiText>
                                )}
                        </EuiModalBody>

                        <EuiModalFooter>
                            {apiSuccess && (
                                <EuiText size="s" >
                                    <p>
                                        <EuiTextColor color="warning" >
                                            <FormattedMessage id="Dashboard.SetEmailModal.successFooterMessage" values={{ email }} />
                                        </EuiTextColor>
                                    </p>
                                </EuiText>
                            )}

                            <EuiButtonEmpty onClick={closeModal}><FormattedMessage id="Dashboard.SetEmailModal.cancel" /></EuiButtonEmpty>

                            <EuiButton onClick={apiSuccess ? closeModal : handleSet} fill isDisabled={loading} isLoading={loading} >
                                <FormattedMessage id={`Dashboard.SetEmailModal.${apiSuccess ? "signIn" : "set"}`} />
                            </EuiButton>
                        </EuiModalFooter>
                    </EuiModal>
                </EuiOverlayMask>
            )}
        </Fragment>
    );
}

export default SetEmailModal;
